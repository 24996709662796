import classNames from "classnames";
import Image from "next/legacy/image";
import Link from "next/link";
import styles from "./VehicleCard.module.scss";

export default function VehicleCard({
  index,
  cta_text,
  make,
  model,
  sub_model,
  price,
  price_black_friday,
  price_prefix,
  cta_link,
  vehicle_image,
  vehicle_image_link,
  rotating_vehicle_image,
  rotating_vehicle_image_link,
  showPricingChanges = false,
}) {
  const secondaryImage =
    rotating_vehicle_image || rotating_vehicle_image_link
      ? rotating_vehicle_image || rotating_vehicle_image_link
      : vehicle_image;
  return (
    <div className={classNames(styles.card, styles[`card_${index}`])}>
      <div className={styles.vehicleDetails}>
        <div className={styles.vehicleMake}>{make}</div>
        <div className={styles.vehicleModel}>
          {model}
          {sub_model && (
            <span className={styles.vehicleSubModel}> {sub_model}</span>
          )}
        </div>
      </div>
      <div className={styles.image}>
        {(vehicle_image || vehicle_image_link) && (
          <Image
            alt=""
            className={styles.rotatingImage}
            src={vehicle_image || vehicle_image_link}
            width="900"
            height="345"
          />
        )}
      </div>
      <div className={styles.rotatingImage}>
        {secondaryImage && (
          <Image
            alt=""
            className={styles.rotatingImage}
            src={secondaryImage}
            width="900"
            height="345"
          />
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.priceText}>
          {price_prefix}
          {` `}
          <span className={styles.price}>
            {showPricingChanges ? price_black_friday : price}
          </span>
        </div>
        <Link href={cta_link} legacyBehavior={true}>
          <a className={classNames(styles.cta, "btn btn-primary")}>
            {cta_text}
          </a>
        </Link>
      </div>
    </div>
  );
}
