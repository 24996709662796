import classNames from "classnames";
import {useContext, useState} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import VehicleCard from "./VehicleCard";
import styles from "./VehiclesAvailableSection.module.scss";
import parse, {domToReact} from "html-react-parser";
import ExpandingToNewStatesModal from "../../ExpandingToNewStates/ExpandingToNewStatesModal";
import PaymentReservationContext from "../../context/paymentReservation";
import {formatCurrency} from "../../../lib/utils/numbers";
import {useLgScreenMediaQuery} from "../../hooks/usePresetMediaQuery";
import useOnlyOnMount from "../../hooks/useOnlyOnMount";

export default function VehiclesAvailableSection({showPricingChanges = false}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {vehicles_available_section} = useContext(ButterCMSContentContext);
  const {actualLowestPricingOption} = useContext(PaymentReservationContext);
  const {heading, vehicles, disclosure} = vehicles_available_section || {};

  if (!vehicles_available_section) {
    return null;
  }

  return (
    <div className={styles.background}>
      <ExpandingToNewStatesModal
        onHide={() => setIsModalOpen(false)}
        show={isModalOpen}
      />
      <div className="container">
        <h2 className={styles.heading}>{heading}</h2>
      </div>
      <div className={classNames(styles.vehiclesContainer, "container")}>
        <div className="row">
          {vehicles
            ? vehicles.map((vehicle, index) => (
                <div
                  key={`${vehicle.make}_${vehicle.model}`}
                  className={classNames(styles.vehicleCol, "col-12 col-md-6")}
                >
                  <VehicleCard
                    {...vehicle}
                    index={index}
                    showPricingChanges={showPricingChanges}
                    price={
                      vehicle.model === "Model 3" &&
                      actualLowestPricingOption?.monthly
                        ? `${formatCurrency(
                            actualLowestPricingOption?.monthly / 100
                          )} / Mo.*`
                        : vehicle.price
                    }
                  />
                </div>
              ))
            : ""}
        </div>
      </div>
      {disclosure && (
        <div className="container">
          <div className={styles.disclosure}>
            {parse(disclosure, {
              replace: ({attribs, children}) => {
                if (attribs?.id === "expandLocationsModal") {
                  return (
                    <button
                      className={styles.expandLocationsModalLink}
                      onClick={() => setIsModalOpen(true)}
                    >
                      {domToReact(children)}
                    </button>
                  );
                }
              },
            })}
          </div>
        </div>
      )}
    </div>
  );
}
