import classNames from "classnames";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
import {useContext} from "react";
import {useInView} from "react-intersection-observer";
import ButterCMSContentContext from "../../context/butterCMSContent";
import styles from "./TestimonialSection.module.scss";
import parse from "html-react-parser";

import {Row, Col} from "react-bootstrap";
import stars from "/public/images/home-v3/stars.png";

const TestimonialCarousel = dynamic(() => import("./TestimonialCarousel"), {
  loading: () => null,
  ssr: false,
});

export default function TestimonialSection({fromPartners = false}) {
  const {testimonials_section} = useContext(ButterCMSContentContext);
  const {ref, inView} = useInView({triggerOnce: true});

  const fields = testimonials_section;

  const headline = fields?.headline || "";
  const subheadline = fields?.subheadline || "";
  const rating = fields?.rating || "";
  const numOfRatings = fields?.number_of_ratings || "";
  const slides = fields?.testimonials || [];
  const features =
    fields?.feature?.map((feature, i) => {
      const isWSJ = feature.image_alt_tag.includes("The Wall");
      const isBenzinga = feature.image_alt_tag.includes("Benzinga");
      const isBloomberg = feature.image_alt_tag.includes("Bloomberg");
      const isLABiz = feature.image_alt_tag.includes("Los Angeles");
      const isDotLA = feature.image_alt_tag.includes("DotLA");
      const isIHeartRadio = feature.image_alt_tag.includes("iHeart");
      const isKTLA = feature.image_alt_tag.includes("KTLA");
      const isElectrek = feature.image_alt_tag.includes("Electrek");

      return (
        <div
          className={classNames(
            styles.logo_wrapper,
            {[styles.wsj_logo]: isWSJ},
            {[styles.benzinga_logo]: isBenzinga},
            {[styles.bloomberg_logo]: isBloomberg},
            {[styles.la_biz_logo]: isLABiz},
            {[styles.dot_la_logo]: isDotLA},
            {[styles.iheart_logo]: isIHeartRadio},
            {[styles.ktla_logo]: isKTLA},
            {[styles.electrek_logo]: isElectrek}
          )}
          key={i}
        >
          <Image
            className={styles.featureLogo}
            src={feature.image}
            alt={feature.image_alt_tag}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      );
    }) || [];

  return (
    <section id="testimonials" className={styles.main}>
      <Row className={styles.main_row}>
        <Col
          xs={12}
          lg={5}
          className={classNames(
            styles.left_col,
            "d-flex flex-column justify-content-center align-items-start"
          )}
        >
          <div className={styles.text_div}>
            {headline && <h2 className={styles.headline}>{parse(headline)}</h2>}

            {rating && (
              <div className={styles.ratings_div}>
                <span className={styles.stars_container}>
                  <Image
                    src={stars}
                    alt="Star ratings"
                    width={118}
                    height={24}
                    quality={90}
                  />
                </span>
                <span className={styles.rating}>{rating}</span>
                {numOfRatings && <span>{`${numOfRatings}`}</span>}
              </div>
            )}

            {subheadline && (
              <p className={styles.subheadline}>{parse(subheadline)}</p>
            )}
          </div>
        </Col>
        <Col
          xs={12}
          lg={7}
          className={classNames(
            styles.right_col,
            "flex-column justify-content-center align-items-center"
          )}
          ref={ref}
        >
          {inView && <TestimonialCarousel slides={slides} />}
        </Col>
      </Row>
      {!fromPartners && features.length > 0 && (
        <div className={styles.features_main}>{features}</div>
      )}
    </section>
  );
}
